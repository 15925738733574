<template>
  <b-card no-body>
    <b-row class="mx-2 mt-2">
      <b-col>
        <div
          class="d-flex flex-column justify-content-center align-items-center"
          @click="openEditImage"
        >
          <b-avatar
            :src="avatarImage"
            size="10rem"
            badge
            badge-right
            badge-top
            badge-offset="-10px"
            badge-variant="transparent"
            class="cursor-pointer"
          >
            <template #badge>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-icon rounded-circle"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
            </template>
          </b-avatar>

          <b-form-file
            ref="userImage"
            v-model="image"
            class="d-none"
            @change="inputImageRenderer($event)"
            accept="image/*"
          ></b-form-file>
          <h4 class="mt-1">{{ firstName }} {{ lastName }}</h4>
        </div>
      </b-col>
    </b-row>
    <div class="m-2">
      <!-- form -->
      <validation-observer ref="updateProfileForm" #default="{ invalid }">
        <b-form @submit.prevent="updateProfile">
          
          <h4>Edit Account Information</h4>
          <b-row class="mt-1">
            <b-col lg="6" xl="3">
              <b-form-group label-for="firstName" label="First Name">
                <validation-provider
                  #default="{ errors }"
                  name="First Name"
                  vid="firstName"
                  rules="required"
                >
                  <b-form-input
                    id="firstName"
                    v-model="firstName"
                    name="firstName"
                    placeholder="John"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col lg="6" xl="3">
              <!-- last name -->
              <b-form-group label-for="lastName" label="Last Name">
                <validation-provider
                  #default="{ errors }"
                  name="Last Name"
                  vid="lastName"
                  rules="required"
                >
                  <b-form-input
                    id="lastName"
                    v-model="lastName"
                    name="lastName"
                    placeholder="Doe"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col lg="6" xl="3">
              <!-- email -->
              <b-form-group label="Email" label-for="email">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="email"
                    v-model="email"
                    name="email"
                    placeholder="john@example.com"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col lg="6" xl="3">
               <b-form-group label="Phone Number" label-for="phone">
                <b-input-group>
                  <v-select
                    v-model="country"
                    :options="countries"
                    label="text"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :reduce="(country) => country.value"
                    class="col-4 col-g-0"
                  />
                  <validation-provider
                    #default="{ errors }"
                    name="Phone"
                    vid="phone"
                    rules="required|integer"
                    class="col-8 col-g-0"
                  >
                    <b-form-input
                      id="phone"
                      type="tel"
                      v-model="phone"
                      :state="errors.length > 0 ? false : null"
                      placeholder="729542528"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small class="text-danger" v-if="invalidPhone">Invalid Phone Number</small>
                  </validation-provider>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
 

          <h4 class="mt-2">Edit Password(<small>If you do not wish to update password, leave the below fields empty</small>)</h4>
          <b-row class="mt-1">
            <b-col md="6" lg="4">
              <!-- password -->
              <b-form-group label="Old Password" label-for="old-password">
                <validation-provider
                  #default="{ errors }"
                  name="Old Password"
                  vid="old-password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="old-password"
                      v-model="oldPassword"
                      :type="password3FieldType"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      name="oldPassword"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="password3ToggleIcon"
                        @click="togglePassword3Visibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6" lg="4">
              <!-- password -->
              <b-form-group label="Password" label-for="password">
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="password"
                      v-model="password"
                      :type="password1FieldType"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      name="password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="password1ToggleIcon"
                        @click="togglePassword1Visibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6" lg="4">
              <!-- confirm password -->
              <b-form-group
                label-for="cPassword"
                label="Confirm Password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Confirm Password"
                  rules="confirmed:password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="cPassword"
                      v-model="cPassword"
                      :type="password2FieldType"
                      class="form-control-merge"
                      :state="errors.length > 0 ? false : null"
                      name="cPassword"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="password2ToggleIcon"
                        @click="togglePassword2Visibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2"
            type="submit"
            :disabled="
              invalid ||
              invalidPhone ||
              formIsLoading ||
              passwordInvalid
            "
          >
            <b-spinner small v-if="formIsLoading" />
            &nbsp; Update Profile &nbsp;
          </b-button>
        </b-form>
      </validation-observer>
    </div>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BButton,
  BLink,
  BSpinner,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormFile,
  BForm,
  BCard,
  BFormRadio,
  BFormCheckbox,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
} from "bootstrap-vue";
import { required, email, integer } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { $themeConfig } from "@themeConfig";
import useJwt from "@/auth/jwt/useJwt";

export default {
  directives: {
    Ripple,
  },
  components: {
    BButton,
    BLink,
    BSpinner,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormFile,
    BForm,
    BCard,
    BFormRadio,
    BFormCheckbox,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  computed: {
    avatarImage() {
      if (this.previewImage) {
        return this.previewImage;
      } else if (this.reqImage) {
        return this.appClientUrl + "/uploads/profile-pictures/" + this.reqImage;
      }
      return require("@/assets/images/avatars/default-profile.png");
    },
    password1ToggleIcon() {
      return this.password1FieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    password2ToggleIcon() {
      return this.password2FieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    password3ToggleIcon() {
      return this.password3FieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    invalidPhone(){
      if(this.phone == "" || this.phone == null){
        return false;
      }
      let phoneSanitize = this.phone
      phoneSanitize = phoneSanitize.replaceAll(" ", '')
      if(phoneSanitize.substr(0, 1) == "+"){
        phoneSanitize = phoneSanitize.substr(1)
      }
      if(phoneSanitize.substr(0, 2) == "00"){
        phoneSanitize = phoneSanitize.substr(2)
      }
      if(phoneSanitize.substr(0, 1) == "0"){
        phoneSanitize = phoneSanitize.substr(1)
      }
      if(phoneSanitize.substr(0, 3) == "254"){
        phoneSanitize = phoneSanitize.substr(3)
      }

      if (phoneSanitize.length == 9) {
        return false;
      } else {
        return true;
      }
    },
    passwordInvalid(){
      if(this.password && this.password != "" && ((!this.cPassword && this.cPassword == "" ) || (!this.oldPassword && this.oldPassword == ""))){
        return true;
      }else {
        return false;
      }
    }
  },
  data() {
    const { appClientUrl } = $themeConfig.app;
    return {
      firstName: "",
      lastName: "",
      email: "",
      reqEmail:"",
      password: "",
      cPassword: "",
      oldPassword:"",
      phone: "",
      reqPhone:"",
      image: null,
      previewImage: null,
      reqImage: null,
      country: null,
      reqCountry: null,
      // validation
      required,
      email,
      integer,
      countries: [],
      formIsLoading: false,
      // Toggle Password
      password1FieldType: "password",
      password2FieldType: "password",
      password3FieldType: "password",
      appClientUrl: appClientUrl,
    };
  },
  methods: {
    inputImageRenderer(event) {
      let reader = new FileReader();
      reader.onload = () => {
        this.previewImage = reader.result;
      };
      reader.readAsDataURL(event.target.files[0]);
    },
    openEditImage() {
      this.$refs.userImage.$el.childNodes[0].click();
    },
    togglePassword1Visibility() {
      this.password1FieldType =
        this.password1FieldType === "password" ? "text" : "password";
    },
    togglePassword2Visibility() {
      this.password2FieldType =
        this.password2FieldType === "password" ? "text" : "password";
    },
    togglePassword3Visibility() {
      this.password3FieldType =
        this.password3FieldType === "password" ? "text" : "password";
    },
    updateProfile() {
      this.formIsLoading = true;
      this.$refs.updateProfileForm.validate().then(success => {
        if (success) {
          let formData = new FormData();
          formData.append('first_name', this.firstName);
          formData.append('last_name', this.lastName);
          formData.append('image', this.image);
          formData.append('old_image', this.reqImage);

          formData.append('email', this.email);
          formData.append('country_id', this.country);
          formData.append('phone', this.phone);
        
          if(this.password && this.password != "" && this.cPassword && this.cPassword != "" && this.oldPassword && this.oldPassword != ""){
            formData.append('password', this.password);
            formData.append('old_password', this.oldPassword);
          }
          this.$http.put('/user/update',formData,
            {
              headers: {
                  'Content-Type': 'multipart/form-data'
              }
            })
            .then( _ => {
                this.logout("User Updated Successfully, Kindly login back again");
            })
            .catch(error => {
               for (let err of error.response.data.error) {
                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title:err,
                      icon: "AlertCircleIcon",
                      variant: "danger",
                    },
                  });
                }
                this.formIsLoading = false;
            })
        }
      });
    },
    logout(logoutMsg) {
      this.$http
        .get("/logout")
        .then((_) => {
          // Remove userData from localStorage
          // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
          localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
          localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);

          // Remove userData from localStorage
          localStorage.removeItem("userData");
          this.$router
            .push({
              name: "login",
            })
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: logoutMsg,
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
            });
          this.formIsLoading = false;
          
        })
        .catch((error) => {
          for (let err of error.response.data.error) {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: err,
                icon: "AlertCircleIcon",
                variant: "danger",
              },
            });
          }
          this.formIsLoading = false;
        });
    },
  },
  created() {
    this.$http.get("/user/").then((response) => {
      const user = response.data.data;
      this.firstName = user.first_name;
      this.lastName = user.last_name;
      this.email = user.email;
      this.reqEmail = user.email;
      this.phone = user.phone;
      this.reqPhone = user.phone;
      this.reqImage = user.image;
      this.website = user.website;
      this.isCompany = user.is_company;
      
      this.$http
      .get("/countries/active")
      .then((response) => {
        for (const country of response.data.data) {
          let pushCountry = {
            value: country.ID,
            text: country.code,
          };
          this.countries.push(pushCountry);
        }
        this.country = user.country_id;
        this.reqCountry = user.country_id;
      })
      .catch((error) => {
        for (let err of error.response.data.error) {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: err,
              icon: "AlertCircleIcon",
              variant: "danger",
            },
          });
        }
      });
    });
  },
  mounted(){
    this.oldPassword = "x";
    setTimeout(() => {
      this.oldPassword = "";
    }, 300);
  }
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.v-select {
  padding-left: 0px !important;
  .vs__actions {
    display: none !important;
  }
}
.col-g-0 {
  padding-right: 0;
}
</style>